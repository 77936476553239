import React from "react";
import {Layout,Heading} from '../../components'
import Video from '../../page-components/video'
import PropTypes from "prop-types";

const CHANNEL ='PLAqC5IzZ127Oa6n6IZ283Be0o3CMSP5sh'; //OlamideVEVO

const Component = (props) => {
  return (
    <Layout>
      <Heading heading='Videos'/>
      <div className='row tt-wrap margin-top-40' >
        <Video height={500} width={'100%'} channel={CHANNEL}/>
      </div>
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
