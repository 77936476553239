// @flow
import React from "react";
import PropTypes from "prop-types";

const prepareSrc = channel => {
  //return `https://www.youtube.com/embed/?listType=user_uploads&list=${channel}`;
  return `https://www.youtube.com/embed/videoseries?list=${channel}`
};

const Component = ({ channel,width,height }: { channel: string,width?:number,height?:number }) => {
  return <iframe allowFullScreen frameBorder={0} src={prepareSrc(channel)} width={width} height={height} />;
};

Component.propTypes = {};
Component.defaultProps = {
  width:480,
  height:400
};

export default Component;
